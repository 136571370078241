﻿/*
--------------------------------------------------------------------------------
youku player
--------------------------------------------------------------------------------
*/

ori.addModule("youkuplayer", {
    /**
    * Initialization.
    */
    init: function () {
        //Load player api asynchronously.
        var self = this,
            scriptTag = document.createElement("script"),
            bodyTag = document.getElementsByTagName("body")[0],
            play = false,
            youkuID = "youkuplayer",
            videoId = "",
            inited = "data-youku-inited",
            notInited = ":not([data-youku-inited])",
            buttonsOpenVideo = "a.js-ykplayer",
            $placeholders = $(buttonsOpenVideo + notInited),
            $youkuWrapper = $("<div />").attr("id", youkuID).addClass("video-youku video").css({ 'height': "100%" }),
            SIZE_4_INDEX = 3,
            SIZE_5_INDEX = 4,
            activeBreakpoint = ori.device ? ori.device.activeBreakpoint.index : SIZE_5_INDEX;

        // TODO: wrong logic - again checking for ori.device? should be at start & the if should be inverted
        if ($placeholders.length && ori.device) {
            // add youku API JavaScript to the document head
            var scriptSrc = function () {
                if (window.location.protocol === "https:") {
                    scriptTag.src = "https://player.youku.com/jsapi";
                } else {
                    scriptTag.src = "http://player.youku.com/jsapi";
                }
                return scriptTag;
            };

            bodyTag.appendChild(scriptSrc());

            // wrap placeholder with a youku wrapper
            $placeholders.each(function () {
                var $placeholder = $(this);
                videoId = $placeholder.data("videoid").replace("=", "");

                // insert youku script with videoplayer inicialization, has to be generated this way, 
                // cause of youku jsapi, which parse script content for pure object player creation :(
                // this content should be issue in the future, cause it's not common behavior for many browsers
                insertVideo = function ($placeholder) {
                    var placeholdersDimensions = {
                        width: $placeholder.width(),
                        height: $placeholder.height()
                    };
                    var width = placeholdersDimensions.width;
                    var height = placeholdersDimensions.width * (3 / 4) > placeholdersDimensions.height
                        ? placeholdersDimensions.height
                        : placeholdersDimensions.width * (3 / 4);

                    //check proper size for video in case we use it in slider (for example on brand campaigns)
                    if (width == 0) {
                        var placeholderImage = $placeholder.closest(".ui-video-preview");
                        if (!placeholderImage.length) {
                            placeholderImage = $placeholder.find("img");
                        }
                        if (placeholderImage.length) {
                            width = placeholderImage.width();
                            height = placeholderImage.height();
                        }
                    }
                    if (width != 0 && height != 0) {
                        var originalId = $placeholder.data("videoid"),
                            videoId = originalId.replace("=", ""),
                            resourceJS = scriptSrc(),
                            jsEmbedScript = 'player = new YKU.Player("' + videoId +
                                '", { styleid: "0", client_id: "c3a028160943ced1", width: "' + width +
                                '", height: "' + height + '", vid: "' + originalId + '", autoplay: "true", newPlayer: true});';

                        resourceJS.type = "text/javascript";
                        resourceJS.id = "_youkujs_";
                    }

                    if (activeBreakpoint >= SIZE_4_INDEX && location.hash.search("play-video") > -1) {
                        resourceJS.appendChild(document.createTextNode(jsEmbedScript));
                        $placeholder[0].parentNode.appendChild(resourceJS);
                    } else {
                        eval(jsEmbedScript); // TODO: especially ugly!
                    }
                };

                if (videoId.length > 0) {
                    $youkuWrapper.attr("id", videoId);
                }

                var $videoHolder = $("#" + videoId);
                $videoHolder.length === 1 && $videoHolder.wrap($youkuWrapper);
                if (!$videoHolder.length)
                    $placeholder.wrap($youkuWrapper);

                // image click event handler
                $placeholder.on(self.eventString("click"), function (e) {
                    e.preventDefault();
                    insertVideo($placeholder);
                    play = true;
                });

                // autoplaing on Beauty Edit BP4+5 page => URL has #play-video
                if (activeBreakpoint >= SIZE_4_INDEX && location.hash.search("play-video") > -1) {
                    $placeholders.click();
                }

                //Mark button as inited to prevent double initialization
                $placeholder.attr(inited, "true");
            });
        }
    }
});